<template>
  <div class="bg-qwr-haze-150 rounded overflow-hidden">
    <div class="grid grid-cols-12 w-full cursor-pointer" @click="isOpen = category.products.length ? !isOpen : false">
      <div class="p-6 flex justify-start items-center gap-4 col-span-full" :class="{
          'lg:col-span-7': category.image,
          'lg:col-span-12': !category.image,
          'h-32': category.description,
          'h-20': !category.description
        }
      ">
        <div class="w-full">
          <div class="w-full flex flex-col gap-2 justify-between">
            <h1 class="m-0 break-all leading">{{ category.name }}</h1>

            <span v-if="category.description && category.products.length > 0">{{ category.description }}</span>
          </div>
        </div>
      </div>

      <div class="order-first lg:order-last col-span-full lg:col-span-5" v-if="category.image">
        <Image :src="category.image" class="w-full object-cover object-center max-h-72" :class="{'lg:max-h-32': category.description, 'lg:max-h-20': !category.description}" />
      </div>
    </div>

    <div v-if="category.products.length > 0" class="p-6 pt-0 lg:pt-6 flex flex-col gap-4">
      <div class="grid grid-cols-1 lg:grid-cols-12 space-y-6 lg:space-y-0">
        <div class="col-span-8">
          <UspList v-if="category.usps" :usps="category.usps.slice(0,3)" gap="gap-2" autoflow />
        </div>
        <div class="col-span-4 flex justify-start lg:justify-end items-end">
          <div v-if="category.products.length > 0 && category.price">
            <div>{{ $t('extra_charge') }}:</div>
            <Price class="leading-6" :value="category.price" :prefix="$t('from_price')" :affix="$t('per_person_per_night')" />
          </div>
          <span v-if="category.products.length > 0 && category.price === ''">{{ $t('no_extra_charge') }}</span>
        </div>
      </div>
    </div>

    <div class="p-6 border-t border-gray-300 flex flex-row justify-between font-bold items-center cursor-pointer" v-if="category.products.length > 0" @click="isOpen = category.products.length ? !isOpen : false">
      <span class="">{{ isOpen? $t('hide'): $t('view') }} {{category.name.toLowerCase()}}</span>
      <ChevronIcon class="h-4 flex-none transition-transform" v-if="category.products.length" :class="{'rotate-90': !isOpen, '-rotate-90': isOpen}" />
    </div>

    <div class="p-4 flex flex-col gap-6 bg-qwr-haze-100 rounded-b" v-show="isOpen && category.products.length > 0">
      <HotelRoomChoiceCard v-for="product in category.products" :key="product.id" group="hotelroom" :product="product" />

      <div class="flex justify-center py-3 cursor-pointer items-center gap-4" @click="isOpen = category.products.length ? !isOpen : false">
        <ChevronIcon class="h-3 flex-none transition-transform" :class="{'rotate-90': !isOpen, '-rotate-90': isOpen}" />
        {{ $t('fold') }}
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
  import type { HotelRoomCategoryResource } from '@/api/resources';
  import HotelRoomChoiceCard from '@/components/HotelRoomChoiceCard.vue';
  import ChevronIcon from '@/theme/icons/chevron.svg?component';
  import {ref} from 'vue';
  import Image from "@/components/Image.vue";
  import Price from "@/components/Price.vue";
  import UspList from "@/components/UspList.vue";

  export interface Props {
    category: HotelRoomCategoryResource,
  }

  const props = defineProps<Props>();
  const isOpen = ref(false);
</script>
